<template>
    <div class="bookings-confirm">
        <transition name="fade">
            <save-template-modal v-if="showModal" @hide="showModal = false;" />
        </transition>

        <card body-class="w-full flex__justify-between flex__align-center" class="border-radius-b-0">
            <div class="flex flex__align-center">
                <div class="icon-btn icon-btn-green">
                    <fa :icon="['fal', 'clipboard-check']" />
                </div>

                <div class="f-size-16 f-w-medium ml-2">
                    Shipment 12300000789 is now booked! Don’t forget to print the label and attach it to your goods. 
                </div>
            </div>

            <div class="flex">
                <v-button-dropdown class="bookings-confirm__format ml-4 z-10" :options="['PNG', 'SVG']" position="bottom"></v-button-dropdown>

                <button class="btn btn-primary bookings-confirm__190px ml-4" @click="showModal = true">
                    <fa :icon="['fal', 'download']" class="mr-2" />

                    Download label
                </button>

                <button class="btn bookings-confirm__190px ml-4">
                    <fa :icon="['fal', 'print']" class="mr-2" />

                    Print
                </button>
            </div>
        </card>

        <comments-block class="mt-1px border-radius-t-0" />

        <div class="f-size-18 mt-3">
            Detailed information:
        </div>

        <div class="flex mt-3">
            <card class="w-full p-0 mr-2">
                <confirm-block title="Pickup" class="w-full">
                    <div class="icon-btn icon-btn-blue">
                        <fa :icon="['fal', 'plane-departure']" />
                    </div>
                </confirm-block>

                <confirm-block title="Sender" class="w-full">
                    <div class="icon-btn icon-btn-blue">
                       <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5 4.5C17.5 4.5 17.0221 4.42646 17 5V6.5H14C13.725 6.5 13.5 6.725 13.5 7V8C13.5 8.275 13.725 8.5 14 8.5H17V10C17 10.5 17.5 10.5 17.5 10.5C17.775 10.5 21.5 8 21.5 7.5C21.5 7 17.775 4.5 17.5 4.5ZM7.5 8C9.70938 8 11.5 6.20937 11.5 4C11.5 1.79063 9.70938 0 7.5 0C5.29063 0 3.5 1.79063 3.5 4C3.5 6.20937 5.29063 8 7.5 8ZM10.3 9H9.77812C9.08437 9.31875 8.3125 9.5 7.5 9.5C6.6875 9.5 5.91875 9.31875 5.22188 9H4.7C2.38125 9 0.5 10.8813 0.5 13.2V14.5C0.5 15.3281 1.17188 16 2 16H13C13.8281 16 14.5 15.3281 14.5 14.5V13.2C14.5 10.8813 12.6187 9 10.3 9Z" fill="#00A3FF"/>
                        </svg>
                    </div>
                </confirm-block>
            </card>

            <card class="w-full p-0 mr-2">
                <confirm-block title="Delivery" class="w-full">
                    <div class="icon-btn icon-btn-green">
                        <fa :icon="['fal', 'plane-arrival']" />
                    </div>
                </confirm-block>

                <confirm-block title="Reciever" class="w-full">
                    <div class="icon-btn icon-btn-green">
                       <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 4.5C4.5 4.5 4.0221 4.42646 4 5V6.5H1C0.725 6.5 0.5 6.725 0.5 7V8C0.5 8.275 0.725 8.5 1 8.5H4V10C4 10.5 4.5 10.5 4.5 10.5C4.775 10.5 8.5 8 8.5 7.5C8.5 7 4.775 4.5 4.5 4.5ZM14.5 8C12.2906 8 10.5 6.20937 10.5 4C10.5 1.79063 12.2906 0 14.5 0C16.7094 0 18.5 1.79063 18.5 4C18.5 6.20937 16.7094 8 14.5 8ZM11.7 9H12.2219C12.9156 9.31875 13.6875 9.5 14.5 9.5C15.3125 9.5 16.0812 9.31875 16.7781 9H17.3C19.6188 9 21.5 10.8813 21.5 13.2V14.5C21.5 15.3281 20.8281 16 20 16H9C8.17188 16 7.5 15.3281 7.5 14.5V13.2C7.5 10.8813 9.38125 9 11.7 9Z" fill="#20984B"/>
                        </svg>
                    </div>
                </confirm-block>
            </card>
        </div>

        <div class="mt-3">
            <card class="w-full p-0 mr-2" body-class="flex__column">
                 <div class="pt-4 pl-6 pb-4 pr-6 flex flex__align-center bb-1px w-full">
                    <div class="icon-btn icon-btn-blue">
                        <fa :icon="['fal', 'truck']" />
                    </div>

                    <div class="f-size-16 f-w-medium ml-2 color-grey-65">
                        Carrier & Service
                    </div>
                </div>

                <div class="flex w-full pl-6 pt-4 pr-6 pb-4">
                    <div 
                        v-for="item, i in 
                        [
                            { title: 'Mode', value: 'Plane' },
                            { title: 'Carrier', value: 'DHL' },
                            { title: 'Serivce', value: 'DHL Express Wordwide' },
                            { title: 'Price', value: '320.80 GBP' },
                        ]"
                        :key='i'
                        class="w-5p"
                    >
                        <div class="f-s-10 mb-1 color-grey uppercase">{{ item.title }}</div>
            
                        <div class="f-size-14">
                            {{ item.value }}
                        </div>
                    </div>

                    <div class="w-5p"></div>
                </div>
            </card>
        </div>  

        <div class="mt-3">
            <card class="w-full p-0 mr-2" body-class="flex__column">
                 <div class="pt-4 pl-6 pb-4 pr-6 flex flex__align-center bb-1px w-full">
                    <div class="icon-btn icon-btn-blue">
                        <fa :icon="['fal', 'clipboard-list']" />
                    </div>

                    <div class="f-size-16 f-w-medium ml-2 color-grey-65">
                        Shipment Details
                    </div>
                </div>

                <div class="flex w-full pl-6 pt-4 pr-6 pb-4 bb-1px">
                    <div 
                        v-for="item, i in 
                        [
                            { title: 'Incoterms', value: 'EXW' },
                            { title: 'Location', value: 'Halmstad' },
                            { title: 'Serivce', value: 'DHL Express Wordwide' },
                            { title: 'Price', value: '320.80 GBP' },
                        ]"
                        :key='i'
                        class="w-5p"
                    >
                        <div class="f-s-10 mb-1 color-grey uppercase">{{ item.title }}</div>
            
                        <div class="f-size-14">
                            {{ item.value }}
                        </div>
                    </div>

                    <div class="w-5p"></div>
                </div>

                <div class="flex w-full pl-6 pt-4 pr-6 pb-4 bb-1px">
                    <div 
                        v-for="item, i in 
                        [
                            { title: 'Project number', value: '1234' },
                            { title: 'Sales order number', value: '12345' },
                            { title: 'Consignee reference', value: 'Cookie Monster' },
                            { title: 'Consignor reference', value: 'Don Quixote' },
                            { title: 'Purchase order number', value: '123456789' },
                        ]"
                        :key='i'
                        class="w-5p"
                    >
                        <div class="f-s-10 mb-1 color-grey uppercase">{{ item.title }}</div>
            
                        <div class="f-size-14">
                            {{ item.value }}
                        </div>
                    </div>
                </div>

                <div class="flex w-full pl-6 pt-4 pr-6 pb-4 bb-1px">
                    <div 
                        v-for="item, i in 
                        [
                            { title: 'Content', value: 'Broken promises' },
                            { title: 'Value', value: '100' },
                            { title: 'Currency', value: 'GBP' },
                        ]"
                        :key='i'
                        class="w-5p"
                    >
                        <div class="f-s-10 mb-1 color-grey uppercase">{{ item.title }}</div>
            
                        <div class="f-size-14">
                            {{ item.value }}
                        </div>
                    </div>

                    <div class="w-5p"></div>
                    <div class="w-5p"></div>
                </div>

                <div class="flex w-full pl-6 pt-4 pr-6 pb-4">
                    <div class="f-size-14 mr-6 cursor-pointer">
                        <fa :icon="['fal', 'link']" />
                        Doc_120721.pdf
                    </div>

                    <div class="f-size-14 mr-6 cursor-pointer">
                        <fa :icon="['fal', 'link']" />
                        Doc_120721.pdf
                    </div>
                </div>
            </card>
        </div>  

        <div class="mt-3">
            <card class="w-full p-0 mr-2" body-class="flex__column">
                <div class="pt-4 pl-6 pb-4 pr-6 flex flex__align-center bb-1px w-full">
                    <div class="icon-btn icon-btn-blue">
                        <fa :icon="['fal', 'file-alt']" />
                    </div>

                    <div class="f-size-16 f-w-medium ml-2 color-grey-65">
                        Invoice Details & Articles
                    </div>
                </div>

                <div class="flex w-full pl-6 pt-4 pr-6 pb-4 bb-1px">
                    <div 
                        v-for="item, i in 
                        [
                            { title: 'Invoice number', value: '123456789' },
                            { title: 'Invoice date', value: '2021-03-31' },
                            { title: 'Currency', value: 'GBP' },
                            { title: 'Declaration statement', value: '–' },
                            { title: 'Incoterm', value: 'EXW' },
                        ]"
                        :key='i'
                        class="w-5p"
                    >
                        <div class="f-s-10 mb-1 color-grey uppercase">{{ item.title }}</div>
            
                        <div class="f-size-14">
                            {{ item.value }}
                        </div>
                    </div>
                </div>

                <div class="flex w-full pl-6 pt-4 pr-6 pb-4 bb-1px">
                    <div 
                        v-for="item, i in 
                        [
                            { title: 'Insurance', value: '–' },
                            { title: 'VAT no buyer', value: '-' },
                            { title: 'vat no sender', value: '–' },
                            { title: 'vat no reciever', value: '–' },
                        ]"
                        :key='i'
                        class="w-5p"
                    >
                        <div class="f-s-10 mb-1 color-grey uppercase">{{ item.title }}</div>
            
                        <div class="f-size-14">
                            {{ item.value }}
                        </div>
                    </div>

                    <div class="w-5p"></div>
                </div>

                <confirm-table />
            </card>
        </div>

        <div class="mt-3">
            <div class="bookings-confirm__packages w-full">
                <div 
                    v-for="item, i in 8"
                    :key="i"
                    class="bookings-confirm__package"
                >
                    <confirm-package />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommentsBlock from './Comments';
import ConfirmBlock from './ConfirmBlock.vue';
import ConfirmTable from './ConfirmTable.vue';
import ConfirmPackage from './ConfirmPackage.vue';
import SaveTemplateModal from './SaveTemplateModal.vue';

export default {
    data() {
        return {
            showModal: false,
        }
    },
    components: {
        CommentsBlock,
        ConfirmBlock,
        ConfirmTable,
        ConfirmPackage,
        SaveTemplateModal,
    },
}
</script>

<
<style lang="scss">
.bookings-confirm {
    &__packages {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 10px)
    }

    &__package {
        width: calc(100% / 5);
        margin-bottom: 10px;
        padding-right: 10px;

        @media (max-width: 1599px) {
            width: calc(100% / 4);
        }

        @media (max-width: 1439px) {
            width: calc(100% / 3);
        }
    }

    .p-0 {
        padding: 0;

        .card-body {
            padding: 0;
        }
    }

    .bb-1px {
        border-bottom-width: 1px;
    }

    &__190px {
        width: 190px;
    }

    &__format {
        button {
            height: 38.8px;
        }
    }

    .card-body {
        overflow: visible;
    }

    .mt-1px {
        margin-top: 1px;
    }

    .w-5p {
        width: calc(100% / 5);
        padding-right: 12px;
    }
}
</style>