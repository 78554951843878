<template>
    <card class="bookings-shipment-aside w-full">
        <div class="w-full">
            <div class="pt-5 pl-6 pr-6 pb-5 w-full bb-1">
                <div class="f-size-16 f-w-medium mb-3">References</div>

                <modal-field label="Consignor reference" class="w-full mb-2">
                    <input type="text" />
                </modal-field>

                <modal-field label="Consignee reference" class="w-full mb-2">
                    <input type="text" />
                </modal-field>

                <div class="flex mb-2">
                    <modal-field label="Purchase order #" class="w-full mr-4">
                        <input type="text" />
                    </modal-field>

                    <modal-field label="Sales order #" class="w-full">
                        <input type="text" />
                    </modal-field>
                </div>

                <modal-field label="Project number" class="w-full">
                    <input type="text" />
                </modal-field>
            </div>

            <div class="pt-5 pl-6 pr-6 pb-5 w-full bb-1">
                <div class="f-size-16 f-w-medium mb-3">Terms of deliverys</div>

                <modal-field label="Type" class="w-full mb-2">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>

                <modal-field label="Location" class="w-full">
                    <input type="text" />
                </modal-field>
            </div>

            <div class="pt-5 pl-6 pr-6 pb-5 w-full bb-1">
                <div class="f-size-16 f-w-medium mb-6">Payment details</div>

                <div class="flex mb-5">
                    <label class="cursor-pointer">
                        <input type="radio" class="form-radio no-branding mr-2"/>

                        Import acct
                    </label>

                    <label class="ml-6 cursor-pointer">
                        <input type="radio" class="form-radio no-branding mr-2"/>

                        Export acct
                    </label>
                </div>

                <modal-field label="Account number" class="w-full">
                    <input type="text" />
                </modal-field>
            </div>

            <div class="pt-5 pl-6 pr-6 pb-5 w-full">
                <div class="f-size-16 f-w-medium mb-6">Attached documents</div>

                <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2" v-if="!documents.length">No attached files</div>

                <div>
                    <template v-for="(item, i) in documents">
                        <div :key="i" v-if="item.media" class="mb-3 doc-icon flex flex__align-center flex__justify-between color-blue f-size-14 cursor-pointer">
                            <div class="flex flex__align-center">
                                <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0417 6.09359V1.6665H4.53125C4.09831 1.6665 3.75 2.01481 3.75 2.44775V17.5519C3.75 17.9849 4.09831 18.3332 4.53125 18.3332H15.4688C15.9017 18.3332 16.25 17.9849 16.25 17.5519V6.87484H11.8229C11.3932 6.87484 11.0417 6.52327 11.0417 6.09359ZM13.5303 12.9738L10.3916 16.089C10.1751 16.3042 9.82552 16.3042 9.60905 16.089L6.47038 12.9738C6.13997 12.646 6.37174 12.0832 6.83659 12.0832H8.95833V9.479C8.95833 9.19124 9.19141 8.95817 9.47917 8.95817H10.5208C10.8086 8.95817 11.0417 9.19124 11.0417 9.479V12.0832H13.1634C13.6283 12.0832 13.86 12.646 13.5303 12.9738ZM16.0221 5.08447L12.8353 1.89437C12.6888 1.74788 12.4902 1.6665 12.2819 1.6665H12.0833V5.83317H16.25V5.6346C16.25 5.42953 16.1686 5.23096 16.0221 5.08447Z" fill="#00A3FF"/>
                                </svg>

                                <router-link :to="`/documents/${item.id}`" target="_blank" class="break-words color-blue">
                                    {{ item.name }}
                                </router-link>
                            </div>

                            <div class="flex ml-2">
                                <fa :icon="['fal', 'arrow-to-bottom']" />
                                <fa :icon="['fal', 'trash-alt']" class="ml-1" />
                            </div>
                        </div>
                    </template>
                </div>

                <file-input class="mt-2" />
            </div>
        </div>
    </card>
</template>

<script>
import FileInput from '~/components/FileInput';

export default {
    data() {
        return {
            documents: [],
        }
    },
    components: {
        FileInput,
    },
}
</script>

<style lang="scss">
.bookings-shipment-aside {
    .card-body {
        padding: 0;
    }
}
</style>