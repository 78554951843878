<template>
    <div class="bookings-address">
        <card>
            <div class="flex flex__align-center w-full">
                <div class="icon-btn icon-brand">
                    <fa :icon="['fal', 'file-signature']" />
                </div>

                <div class="f-size-16 f-w-medium ml-2 mb-1">
                    General information
                </div>

                <modal-field label="Direction" class="ml-10 -mt-3 flex-2">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>

                <modal-field label="Recipient type" class="ml-6 -mt-3 flex-2">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>

                <modal-field label="Preferred mode" class="ml-6 -mt-3 flex-2">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>

                <modal-field label="Movement type" class="ml-6 -mt-3 flex-2">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>

                <div class="color-grey-65 ml-6 f-size-14">Return shipment?</div>

                <label class="ml-6 cursor-pointer">
                    <input type="radio" class="form-radio no-branding mr-2"/>

                    No
                </label>

                <label class="ml-6 cursor-pointer">
                    <input type="radio" class="form-radio no-branding mr-2"/>

                    Yes
                </label>
            </div>
        </card>

        <div class="flex mt-3">
            <div class="w-full">
                <address-form name="Sender" />

                <address-form name="Pickup" class="mt-2px" />
            </div>

            <div class="w-full ml-3">
                <address-form name="Reciever" />

                <address-form name="Delivery" class="mt-2px" />
            </div>
        </div>
    </div>
</template>

<script>
import AddressForm from './AddressForm.vue';

export default {
    components: {
        AddressForm,
    },
}
</script>

<style lang="scss">
.bookings-address {
    .card-body {
        overflow: visible;
    }

    .flex-2 {
        flex: 2
    }

    .mt-2px {
        margin-top: 2px;
    }
}
</style>