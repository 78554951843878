<template>
    <modal
        :adaptive="true"
        :show="true"
        height="auto"
        name="calculate"
        scrollable
        size="sm"
        @hide="$emit('hide')"
        class="bookings-request-modal"
    >
        <div slot="header">
            <h2 class="font-normal">Request has been sent!</h2>
        </div>

        <div slot="body">
            <p class="mb-4">Quote request has been sent to the carrier. </p>
            <p class="mb-4">You will receive an email notification as soon as the carrier returns with the price. </p>
            <p class="mb-4">Please allow up to 2 business days for getting the answer</p>
        </div>

        <div slot="footer" class="bookings-request-modal__footer">
            <div class="flex flex__justify-end w-full">
                <button class="btn btn-primary bookings-request-modal__btn" @click="$emit('hide')">OK</button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'BookingsCalculate',
}
</script>

<style lang="scss">
.bookings-request-modal {
    &__footer {
        width: 100%;
    }

    &__btn {
        width: 100px;
    }
}
</style>
