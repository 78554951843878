<template>
    <div class="bookings">
        <page-header title="Bookings" class="w-full" subtitle="Transport">
            <div slot="tools">
                <div class="flex">

                    <button class="btn color-white">
                        Next Step
                    </button>
                </div>
            </div>
        </page-header>

        <card class="bookings__subhead" body-class="p-0 pl-0 pr-6">
            <div class="flex flex__align-center flex__justify-between w-full">
                <tabs @update="tab = $event" />

                <div class="flex flex__align-center">
                    <button title="Save query" type="button" class="icon-btn ml-4">
                        <fa :icon="['fal','save']" />
                    </button>

                    <button title="Load query" type="button" class="icon-btn ml-4">
                        <fa :icon="['fal','folder-open']"/>
                    </button>

                    <div class="w-1 h-6 ml-4 border-r" />

                    <confirm confirm-text="Reset form">
                        <button title="Reset form" type="button" class="hover:text-red-500 icon-btn ml-4">
                            <fa :icon="['fal','undo']" />
                        </button>
                    </confirm>
                </div>
            </div>  
        </card>
        
        <div class="mt-3" v-if="tab === 'address'">
            <address-block />
        </div>

        <div v-if="tab === 'shipment details'" class="mt-3 flex flex__column">
            <div class="flex">
                <div class="w-full mr-3 flex flex__column">
                    <shipment-info-block />

                    <div class="mt-2 flex-2 flex flex__column">
                        <div class="w-full">
                            <div 
                                v-for="item in packages"
                                :key="item"
                                class="bookings__package"
                            >
                                <shipment-package-block />
                            </div>
                        </div>

                        <card class="flex flex__justify-end border-radius-t-0">
                            <button class="btn btn-blue cursor-pointer" @click="packages.push(packages.length + 1)">Add package</button>
                        </card>
                    </div>
                </div>

                <div class="bookings__shipment-aside flex">
                    <shipment-aside-block />
                </div>
            </div>
        </div>

        <div v-if="tab === 'carriers'" class="mt-3">
            <carriers-block />
        </div>

        <div v-if="tab === 'confirmation'" class="mt-3">
            <confirmation-block />
        </div>
    </div>
</template>

<script>
import Tabs from '~/components/Bookings/Tabs';
import AddressBlock from '~/components/Bookings/Address';
import CarriersBlock from '~/components/Bookings/Carriers';
import ShipmentInfoBlock from '~/components/Bookings/ShipmentInfo';
import ShipmentAsideBlock from '~/components/Bookings/ShipmentAside';
import ShipmentPackageBlock from '~/components/Bookings/ShipmentPackage';
import ConfirmationBlock from '~/components/Bookings/Confirmation';

export default {
    data() {
        return {
            packages: [0],
            tab: 'address'
        }
    },
    components: {
        Tabs,
        AddressBlock,
        CarriersBlock,
        ShipmentInfoBlock,
        ShipmentAsideBlock,
        ShipmentPackageBlock,
        ConfirmationBlock,
    },
}
</script>

<style lang="scss">
.bookings {
    &__shipments-aside {
        max-width: 320px;
        width: 100%;
    }

    &__subhead {
        .card-body {
            overflow: visible;
        }
    }

    &__tabs {
        svg {
            height: 7px;

            path {
                fill: rgba(0, 0, 0, 0.35);
            }
        }
    }

    &__package {
        margin-bottom: 2px;

        .card {
            border-radius: 0px;
        }

        &:first-child .card {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            margin-bottom: 1px;
        }
    }

    &__tabs-item {
        padding-left: 28px;
        padding-right: 28px;
        padding-bottom: 13px;
        border-bottom-width: 3px;
        border-bottom-color: transparent;
        color: rgba(0, 0, 0, 0.35);
        transition: color .2s, border .1s;

        &:hover {
            color: rgba(0, 0, 0, 0.60);
        }

        &:first-child {
            border-bottom-left-radius: 3px;
        }

        &.active {
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .btn:disabled:hover {
        color: white !important;
    }

    .bb-1 {
        border-bottom-width: 1px;
    }

    .bt-1 {
        border-top-width: 1px;
    }

    .btn-blue {
        background-color: #00A3FF !important;
        border-color: #00A3FF !important;

        &:hover {
            background-color: #1480bd !important;
            border-color: #1480bd !important;
        }
    }
}
</style>