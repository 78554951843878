<template>
    <div>
        <div class="pt-4 pl-6 pb-4 pr-6 flex flex__align-center bb-1px">
            <slot></slot>

            <div class="f-size-16 f-w-medium ml-2 color-grey-65">
                {{ title }}
            </div>
        </div>

        <div class="pt-4 pb-4 pl-6 pr-6">
            <div class="mb-4">
                <div class="f-s-10 mb-1 color-grey uppercase">Address</div>
            
                <div class="f-size-14 lh-28">
                    1865 West 2100 South, 84119 Salt Lake City, US
                </div>
            </div>

            <div class="mb-4">
                <div class="f-s-10 mb-1 color-grey uppercase">Company</div>
            
                <div class="f-size-14 lh-28">
                    Beijer Electronics Inc
                </div>
            </div>

            <div>
                <div class="f-s-10 mb-1 color-grey uppercase">Contact</div>
            
                <div class="f-size-14 lh-28">
                    Clint Bock<br />
                    Clint.bock@beijerinc.com<br />
                    801-466-8770
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
    },
}
</script>