<template>
    <card class="w-full p-0" body-class="flex__column">
        <div>
            <div class="pt-4 pl-6 pb-4 pr-6 flex flex__align-center bb-1px w-full">
                <div class="icon-btn icon-btn-blue">
                    <fa :icon="['fal', 'box']" />
                </div>

                <div class="f-size-16 f-w-medium ml-2 color-grey-65">
                    Package #1
                </div>
            </div>
        </div>

        <div class="pt-4 pl-6 pb-4 pr-6 flex flex__column">
            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Goods type</div>
                <div class="f-size-14 ml-2 w__50-p truncate">Box/Parcel</div>
            </div>

            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Number of units</div>
                <div class="f-size-14 ml-2 w__50-p truncate">1</div>
            </div>

            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Width</div>
                <div class="f-size-14 ml-2 w__50-p truncate">20 cm</div>
            </div>

            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Height</div>
                <div class="f-size-14 ml-2 w__50-p truncate">5 cm</div>
            </div>

            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Length</div>
                <div class="f-size-14 ml-2 w__50-p truncate">Length</div>
            </div>

            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Volume</div>
                <div class="f-size-14 ml-2 w__50-p truncate">0.003 m3</div>
            </div>

            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Weight</div>
                <div class="f-size-14 ml-2 w__50-p truncate">6 kg</div>
            </div>

            <div class="flex flex__justify-between mb-2">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Load meters</div>
                <div class="f-size-14 ml-2 w__50-p truncate">-</div>
            </div>

            <div class="flex flex__justify-between">
                <div class="f-s-10 color-grey uppercase lh-21 w__50-p">Pallet spaces</div>
                <div class="f-size-14 ml-2 w__50-p truncate">-</div>
            </div>
        </div>
    </card>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.lh-21 {
    line-height: 21px;
}
</style>