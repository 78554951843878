<template>
    <card 
        class="bookings-address-form w-full"
        :class="{
            'border-radius-b-0': !checked,
            'border-radius-t-0': checked,
        }"
    >
        <div class="w-full">
            <div class="bookings-address-form__head flex flex__justify-between pt-4 pl-6 pr-6 pb-4 w-full">
                <div class="flex flex__align-center">
                    <button 
                        class="icon-btn icon-btn-blue icon-btn-custom cursor-default"
                        :class="{'icon-btn-green': name === 'Reciever' || name === 'Delivery'}"
                    >
                        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 6.5V5.04122C17.0042 5.04347 17.0085 5.04579 17.0129 5.04818C17.1281 5.11049 17.2863 5.20401 17.4762 5.32256C17.8544 5.55871 18.3374 5.88112 18.8131 6.21981C19.2903 6.55954 19.7505 6.90879 20.0872 7.19905C20.2232 7.31625 20.3317 7.41751 20.4107 7.5C20.3317 7.5825 20.2232 7.68375 20.0872 7.80095C19.7505 8.09121 19.2903 8.44046 18.8131 8.78019C18.3374 9.11888 17.8544 9.44129 17.4762 9.67744C17.2863 9.79599 17.1281 9.88951 17.0129 9.95182C17.0085 9.95421 17.0042 9.95653 17 9.95879V8.5V8H16.5H13.5V7H16.5H17V6.5ZM20.5289 7.64036C20.5331 7.64736 20.5318 7.64661 20.5281 7.63902C20.5284 7.6395 20.5286 7.63995 20.5289 7.64036ZM20.5281 7.36098C20.5318 7.35339 20.5331 7.35264 20.5289 7.35964C20.5286 7.36005 20.5284 7.3605 20.5281 7.36098ZM10.5 4C10.5 5.93323 8.93323 7.5 7 7.5C5.06677 7.5 3.5 5.93323 3.5 4C3.5 2.06677 5.06677 0.5 7 0.5C8.93323 0.5 10.5 2.06677 10.5 4ZM7 10C7.84791 10 8.65504 9.8192 9.38502 9.5H9.8C11.8426 9.5 13.5 11.1574 13.5 13.2V14.5C13.5 15.052 13.052 15.5 12.5 15.5H1.5C0.948017 15.5 0.5 15.052 0.5 14.5V13.2C0.5 11.1574 2.15739 9.5 4.2 9.5H4.61536C5.34718 9.81893 6.15143 10 7 10Z" stroke="#00A3FF"/>
                        </svg>
                    </button>

                    <div class="f-size-16 f-w-medium ml-2">
                        {{ name }}
                    </div>
                </div>

                <div class="flex flex__align-center">

                    <label v-if="checked" class="color-grey-65 f-size-14 flex flex__align-center cursor-pointer">
                        {{ titleChecked }}

                        <v-checkbox v-model="checkbox" class="ml-4" />
                    </label>

                    <div v-if="checked" class="w-1 h-6 ml-2 border-r" />
                    
                    <div class="icon-btn cursor-pointer ml-4">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.2812 8.25C20.5391 8.25 20.75 8.03906 20.75 7.78125V6.21875C20.75 5.96094 20.5391 5.75 20.2812 5.75H19.5V3.875C19.5 2.83984 18.6602 2 17.625 2H5.125C4.08984 2 3.25 2.83984 3.25 3.875V20.125C3.25 21.1602 4.08984 22 5.125 22H17.625C18.6602 22 19.5 21.1602 19.5 20.125V18.25H20.2812C20.5391 18.25 20.75 18.0391 20.75 17.7812V16.2188C20.75 15.9609 20.5391 15.75 20.2812 15.75H19.5V13.25H20.2812C20.5391 13.25 20.75 13.0391 20.75 12.7812V11.2188C20.75 10.9609 20.5391 10.75 20.2812 10.75H19.5V8.25H20.2812ZM17.625 20.125H5.125V3.875H17.625V20.125Z" fill="black"/>
                            <path d="M15.3169 12.9107C15.4342 12.7935 15.5 12.6345 15.5 12.4688V11.8438C15.5 11.678 15.4342 11.519 15.3169 11.4018C15.1997 11.2846 15.0408 11.2188 14.875 11.2188H12.375V8.71875C12.375 8.55299 12.3092 8.39402 12.1919 8.27681C12.0747 8.1596 11.9158 8.09375 11.75 8.09375H11.125C10.9592 8.09375 10.8003 8.1596 10.6831 8.27681C10.5658 8.39402 10.5 8.55299 10.5 8.71875V11.2188H8C7.83424 11.2188 7.67527 11.2846 7.55806 11.4018C7.44085 11.519 7.375 11.678 7.375 11.8438V12.4688C7.375 12.6345 7.44085 12.7935 7.55806 12.9107C7.67527 13.0279 7.83424 13.0938 8 13.0938H10.5V15.5938C10.5 15.7595 10.5658 15.9185 10.6831 16.0357C10.8003 16.1529 10.9592 16.2188 11.125 16.2188H11.75C11.9158 16.2188 12.0747 16.1529 12.1919 16.0357C12.3092 15.9185 12.375 15.7595 12.375 15.5938V13.0938H14.875C15.0408 13.0938 15.1997 13.0279 15.3169 12.9107Z" fill="black"/>
                        </svg>

                    </div>

                    <div class="icon-btn ml-4 cursor-pointer">
                        <fa :icon="['fal', 'address-book']" />
                    </div>
                </div>
            </div>

            <div v-if="!checked || (checked && checkbox)" class="pt-5 pl-6 pr-6 pb-10 w-full">
                <div class="flex w-full">
                    <modal-field label="Company" class="flex-2">
                        <input type="text" />
                    </modal-field>

                    <modal-field label="Country" required class="w-195 ml-6">
                        <multiselect :options="['option']"></multiselect>
                    </modal-field>

                    <modal-field label="State" class="ml-6">
                        <input type="text" />
                    </modal-field>
                </div>

                <div class="flex w-full mt-6">
                    <modal-field label="City" required class="w-195">
                        <input type="text" />
                    </modal-field>

                    <modal-field label="Address" class="flex-2 ml-6">
                        <input type="text" />
                    </modal-field>

                    <modal-field label="Zip code" class="w-80 ml-6">
                        <input type="text" />
                    </modal-field>
                </div>

                <div class="flex w-full mt-6">
                    <modal-field label="Port of Loading" required class="w-full">
                        <div class="bookings-address-form__port relative w-full">
                            <fa :icon="['fal', 'ship']" class="absolute" />

                            <input type="text" />
                        </div>
                    </modal-field>
                </div>

                <div class="flex w-full mt-6">
                    <modal-field label="Contact person" required class="w-full">
                        <input type="text" />
                    </modal-field>

                    <modal-field label="Phone" required class="w-full ml-6">
                        <input type="text" />
                    </modal-field>

                    <modal-field label="E-mail" required class="w-full ml-6">
                        <input type="text" />
                    </modal-field>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import VCheckbox from '~/components/VCheckbox.vue';

export default {
    props: {
        name: {
            type: String,
        }
    },

    data() {
        return {
            checkbox: false,
        }
    },

    computed: {
        checked() {
            return this.name === 'Pickup' || this.name  === 'Delivery';
        },

        titleChecked() {
            if (this.name === 'Pickup')  {
                return 'Same as Sender';
            }

            if (this.name === 'Delivery')  {
                return 'Same as Reciever';
            }

            return '';
        }
    },
    
    components: {
        VCheckbox
    }
}
</script>

<style lang="scss">
.bookings-address-form {
    &__head {
        border-bottom-width: 1px;
    }

    &__port svg {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }

    &__port input {
        padding-left: 34px !important;
    }

    .card-body {
        padding: 0 !important;
    }

    .flex-2 {
        flex: 2;
    }

    .w-195 {
        width: 195px;
    }

    .w-80 {
        width: 80px;
    }

    .icon-btn-green svg path {
        fill: #20984B;
        stroke: #20984B;
    }
}
</style>