<template>
    <table class="table border-b table-fixed">
        <thead>
            <th 
                v-for="item in columns"
                :key="item.title"
                class="ok"
                :class="item.className"
            >{{ item.title }}</th>
        </thead>

        <tbody v-for="(_item) in 3" :key="_item">
            <tr>
                <td v-for="item in columns" :key="item.title" class="ok">
                    Random words
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    data() {
        return {
            columns: [
                { title: 'Number of articles', className: 'w-48' },
                { title: 'Article number' },
                { title: 'Description' },
                { title: 'Net weight' },
                { title: 'Gross weight' },
                { title: 'Country of origin' },
                { title: 'Commodity code' },
                { title: 'Unit value' },
                { title: 'Line' },
            ],
        }
    }
}
</script>