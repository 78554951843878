<template>
    <card body-class="flex__column" class="bookings-comments">
        <div class="flex flex__align-center flex__justify-between">
            <div class="f-size-16 cursor-pointer" @click="show = !show">
                Shipment comments (2)

                 <fa :icon="['fal', show ? 'chevron-up' : 'chevron-down']" class="ml-2" />
            </div>

            <div v-if="false" class="color-grey">No comments</div>

            <div class="color-green f-size-14 cursor-pointer">
                Add comment +
            </div>
        </div>

        <div v-if="show" class="flex flex__column flex-grow flex__justify-between mt-8"> 
            <div>
                <div 
                    v-for="item in 3"
                    :key="item"
                    class="bookings-comments__item"
                >
                    <div class="flex flex__justify-between flex__align-center">
                        <div class="f-s-10 color-grey uppercase">1. Username</div>
                        <div class="f-s-10 color-grey uppercase">13 jul 11:01</div>
                    </div>

                    <div class="f-size-12 mt-2 bookings-comments__message">Sed etiam sit tellus, viverra dictum orci consectetur orci venenatis. Cursus gravida ullamcorper bibendum ornare. </div>
                </div>
            </div>

            <div class="flex mt-2 mb-2">
                <emoji-text-fields v-model="comment" input class="flex-grow mr-6" />

                <button class="btn-send px-10 btn-primary pl-6 pr-6 background-blue mr-2 border-0">Send</button>

                <button class="icon-btn background-pink btn-close">
                    <fa :icon="['fal', 'times']" class="path-white" />
                </button>
            </div>
        </div>
    </card>
</template>

<script>
import EmojiTextFields from '~/components/EmojiTextFields.vue';

export default {
    data() {
        return {
            comment: '',
            show: false,
        }
    },

    components: {
        EmojiTextFields,
    }
}
</script>

<style lang="scss">
.bookings-comments {
    &__item {
        border-bottom-width: 1px;
        border-bottom-color:rgba(0, 0, 0, 0.05);;
        padding-bottom: 8px;
        margin-bottom: 8px;

        &:last-child {
            border-bottom: 0;
        }
    }

    &__message {
        max-width: 880px;
    }

    .mt-8 {
        margin-top: 32px;
    }

    .background-pink {
        background-color: #fa3b73 !important;
    }

    .btn-close:hover {
        background-color: #cf2f5d !important;
    }

    .btn-send:hover {
        background-color: #1596df !important;
    }
}
</style>