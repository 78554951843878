<template>
    <card class="bookings-shipment-info" body-class="flex__column">
        <div class="pt-4 pl-6 pr-6 pb-6 w-full">
            <div class="flex flex__justify-between w-full mb-6">
                <div class="flex flex__align-center">
                    <div class="icon-btn icon-btn-blue">
                        <fa :icon="['fal', 'boxes']" />
                    </div>

                    <div class="f-size-16 f-w-medium ml-2 mb-1">
                        Goods information
                    </div>
                </div>

                <label class="color-grey-65 f-size-14 flex flex__align-center cursor-pointer">
                    Enter invoice details

                    <v-checkbox v-model="checkbox" class="ml-4" />
                </label>
            </div>

            <div class="flex w-full">
                <modal-field label="Content" required class="w-full">
                    <input type="text" />
                </modal-field>

                <modal-field label="Value" class="w-160px ml-6">
                    <input type="text" />
                </modal-field>

                <modal-field label="Currency" class="w-160px ml-6">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>
            </div>
        </div>

        <div v-if="checkbox" class="pt-6 pl-6 pr-6 pb-6 w-full bt-1">
            <div class="flex flex__justify-between w-full mb-10">
                <div class="flex flex__align-center">
                    <div class="icon-btn icon-btn-blue">
                        <fa :icon="['fal', 'file-alt']" />
                    </div>

                    <div class="f-size-16 f-w-medium ml-2 mb-1">
                        Articles & Invoice Details
                    </div>
                </div>

                <dropdown dropdownClass="w-48">
                    <button class="icon-btn" slot="trigger">
                        <fa :icon="['fal', 'ellipsis-h']" />
                    </button>

                    <ul>
                        <li><button><fa :icon="['fal','folder-open']" class="mr-2" />Load template</button></li>
                        <li><button><fa :icon="['fal','save']" class="mr-2" />Save as template</button></li>
                        <li><button><fa :icon="['fal','copy']" class="mr-2" />Duplicate line</button></li>
                    </ul>
                </dropdown>
            </div>

            <div class="flex flex__align-center w-full">
                <modal-field label="Invoice number" required class="w-full">
                    <input type="text" />
                </modal-field>

                <modal-field label="Invoice Date" required class="w-180px ml-6">
                    <date-picker v-model="date" />
                </modal-field>

                <div class="w-1 h-6 ml-6 border-r mt-4" />

                <modal-field label="Currency" required class="w-160px ml-6">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>

                <div class="w-1 h-6 ml-6 border-r mt-4" />

                <modal-field label="Incoterm" required class="w-160px ml-6">
                    <multiselect :options="['option']"></multiselect>
                </modal-field>

                <div class="w-1 h-6 ml-6 border-r mt-4" />

                <modal-field label="Reason for export" required class="w-full ml-6">
                    <input type="text" />
                </modal-field>
            </div>

            <div class="flex flex__align-center w-full mt-6">
                <modal-field label="Freight charges" class="w-full">
                    <input type="text" />
                </modal-field>

                <div class="w-1 h-6 ml-6 border-r mt-4" />

                <modal-field label="Insurance" class="w-full ml-6">
                    <input type="text" />
                </modal-field>

                <div class="w-1 h-6 ml-6 border-r mt-4" />

                <modal-field label="Vat no sender" class="w-full ml-6">
                    <input type="text" />
                </modal-field>

                <modal-field label="Vat no reciever" class="w-full ml-6">
                    <input type="text" />
                </modal-field>

                <modal-field label="Vat no buyer" class="w-full ml-6">
                    <input type="text" />
                </modal-field>
            </div>
        </div>  

        <div v-if="checkbox" class="w-full bt-1">
            <table class="table border-b table-fixed">
                <thead>
                    <th 
                        v-for="item in columns"
                        :key="item.title"
                        class="ok"
                        :class="item.className"
                    >{{ item.title }}</th>
                </thead>

                <tbody v-for="(_item) in 3" :key="_item">
                    <tr>
                        <td v-for="item in columns" :key="item.title" class="ok">
                            Random words
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </card>
</template>

<script>
import VCheckbox from '~/components/VCheckbox.vue';
import DatePicker from '~/components/DatePicker'

export default {
    components: {
        VCheckbox,
        DatePicker,
    },

    data() {
        return {
            checkbox: false,
            date: null,

            columns: [
                { title: 'Number of articles', className: 'w-48' },
                { title: 'Article number' },
                { title: 'Description' },
                { title: 'Net weight' },
                { title: 'Gross weight' },
                { title: 'Country of origin' },
                { title: 'Commodity code' },
                { title: 'Unit value' },
                { title: 'Line' },
            ],
        }
    }
}
</script>

<style lang="scss">
.bookings-shipment-info {
    .card-body {
        padding: 0;
        overflow: visible;
    }

    .w-160px {
        min-width: 160px;
        width: 160px;
    }

    .w-120px {
        min-width: 120px;
        width: 120px;
    }

    .w-180px {
        min-width: 180px;
        width: 180px;
    }
}
</style>