<template>
    <modal
        :adaptive="true"
        :show="true"
        height="auto"
        name="calculate"
        scrollable
        size="sm"
        @hide="$emit('hide')"
        class="bookings-calculate"
    >
        <div slot="header">
            <h2 class="font-normal">Calculation details</h2>
        </div>

        <div slot="body">
            <div class="flex flex__justify-between mb-5">
                <span class="bookings-calculate__title uppercase color-grey f-s-10">Freight price calculated using price per shipment per shipment</span>

                <span class="uppercase f-size-12">69.00   GBP</span>
            </div>

            <div class="flex flex__justify-between mb-5">
                <span class="bookings-calculate__title uppercase color-grey f-s-10">Freight price calculated using price per shipment per shipment</span>

                <span class="uppercase f-size-12">69.00   GBP</span>
            </div>

            <div class="flex flex__justify-between mb-5">
                <span class="bookings-calculate__title uppercase color-grey f-s-10">Freight price calculated using price per shipment per shipment</span>

                <span class="uppercase f-size-12">69.00   GBP</span>
            </div>
        </div>

        <div slot="footer" class="bookings-calculate__footer">
            <div class="flex flex__justify-between w-full">
                <span class="uppercase color-grey-65 f-size-12">total:</span>

                <span class="uppercase f-size-12">320.87  GBP</span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'BookingsCalculate',
}
</script>

<style lang="scss">
.bookings-calculate {
    &__title {
        max-width: 280px;
    }

    &__footer {
        width: 100%;
    }

    .modal__footer {
        background-color: rgba(0, 0, 0, 0.05);;;
    }
}
</style>
