<template>
    <card class="bookings-shipment-package" body-class="flex__column">
        <div class="flex flex__justify-between w-full mb-8">
            <div class="flex flex__align-center">
                <div class="icon-btn icon-btn-blue">
                    <fa :icon="['fal', 'box']" />
                </div>

                <div class="f-size-16 f-w-medium ml-2 mb-1 mr-6">
                    Package #1
                </div>

                <div class="flex flex__align-center">
                    <div class="bookings-shipment-package__tag cursor-pointer flex flex__align-center f-size-16 pl-4 pr-4 active mr-4">Full pallet</div>

                    <div class="bookings-shipment-package__tag cursor-pointer flex flex__align-center f-size-16 pl-4 pr-4 mr-4">Half pallet</div>
                </div>

                <label class="color-grey-65 f-size-14 flex flex__align-center cursor-pointer ml-2">
                    Stackable

                    <v-checkbox class="ml-4" />
                </label>
            </div>

            <div class="flex flex__align-center">
                <div class="color-pink f-size-14">Delete package</div>

                <div class="w-1 h-6 ml-4 mr-4 border-r" />

                <dropdown dropdownClass="w-48">
                    <button class="icon-btn" slot="trigger">
                        <fa :icon="['fal', 'ellipsis-h']" />
                    </button>

                    <ul>
                        <li><button><fa :icon="['fal','folder-open']" class="mr-2" />Load template</button></li>
                        <li><button><fa :icon="['fal','save']" class="mr-2" />Save as template</button></li>
                        <li><button><fa :icon="['fal','copy']" class="mr-2" />Duplicate line</button></li>
                    </ul>
                </dropdown>
            </div>
        </div>

        <div class="flex flex__align-center w-full">
            <modal-field label="Goods type" required class="w-160px">
                <multiselect :options="['option']"></multiselect>
            </modal-field>

            <div class="w-1 h-6 ml-4 border-r mt-4" />

            <modal-field label="Units" required class="w-60px ml-4">
                <input type="text" />
            </modal-field>

            <div class="w-1 h-6 ml-4 border-r mt-4" />

            <modal-field label="Weight, kg" class="w-full ml-4">
                <input type="text" />
            </modal-field>

            <modal-field label="Load meters, m2" class="w-full ml-4">
                <input type="text" />
            </modal-field>

            <modal-field label="Pallet spaces" class="w-full ml-4">
                <input type="text" />
            </modal-field>

            <div class="w-1 h-6 ml-4 border-r mt-4" />

            <modal-field label="Length, cm" class="w-full ml-4">
                <input type="text" />
            </modal-field>

            <modal-field label="Width, cm" class="w-full ml-4">
                <input type="text" />
            </modal-field>

            <modal-field label="Height" class="w-full ml-4">
                <input type="text" />
            </modal-field>

            <div class="w-1 h-6 ml-4 border-r mt-4" />

            <modal-field label="Volume, m3" class="w-80px ml-4">
                <input type="text" />
            </modal-field>
        </div>
    </card>
</template>

<script>
import VCheckbox from '~/components/VCheckbox.vue';

export default {
    components: {
        VCheckbox,
    },
}
</script>

<style lang="scss">
.bookings-shipment-package {
    .card-body {
        overflow: visible;
    }

    &__tag {
        height: 32px;
        color: #20984B;
        border-radius: 20px;
        border: 1px solid #20984B;
        transition: color .2s, background .2s;

        &.active {
            background-color: #20984B;
            color: white;
        }
    }

    .w-60px {
        width: 60px;
        min-width: 60px;
    }

    .w-80px {
        width: 80px;
        min-width: 80px;
    }

    .w-160px {
        width: 160px;
        min-width: 160px;
    }
}
</style>