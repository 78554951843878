<template>
    <modal
        :adaptive="true"
        :show="true"
        height="auto"
        name="save-template"
        scrollable
        size="middle"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">Save as template?</h2>
        </div>

        <div slot="body">
            <p class="mb-6">Would you like to save this shipment as a template for future bookings?</p>

            <input type="text">
        </div>

        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full w__100-p">
                <div class="flex flex__justify-end">
                    <button class="px-10 btn-primary pl-5 pr-5">Save Template</button>
                    <button class="px-10 btn-red ml-4 pl-5 pr-5" @click="$emit('hide')">Discard</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            value: 1
        }
    }
}
</script>