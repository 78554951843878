<template>
    <div class="bookings-carriers">
        <transition name="fade">
            <calculate v-if="showInfo" @hide="showInfo = false" />
        </transition>

        <transition name="fade">
            <request-modal v-if="showRequest" @hide="showRequest = false" />
        </transition>

        <card body-class="flex flex__align-center flex__justify-between w-full" class="border-radius-b-0 relative">
            <div class="flex flex__align-center">
                <div class="icon-btn icon-btn-blue">
                    <fa :icon="['fal', 'boxes']" />
                </div>

                <div class="f-size-16 f-w-medium ml-2 mb-1">
                    Goods information
                </div>
            </div>

            <div class="flex">
                <icon name="icons/download" key="carrier-export" class="ml-4" />

                <icon name="icons/gear" key="carrier-export" class="ml-4" />
            </div>
        </card>

        <card bodyClass="p-0 -mx-6" class="border-radius-t-0 bookings-carriers__table">   
            <v-table
                :id="'bookings-carriers'"
                :columns="columns"
                :loading="tableLoading"
                :filter-params="filterParams"
                :panel="false"
                :tools="false"
                row-key="uuid"
                ref="purchse-users"
                :endpoint="$apiUrl.users.list"
                sort-key="created_at"
                query-params
                no-cache
                paginate
                @row-click="rowClicked"
            >
                <template slot="cell" slot-scope="{row, col}">
                    <span v-if="col.key === 'status'">
                        <div class="pl-1 pt-1 pb-1 pr-2 bookings-carriers__status success flex flex__align-center f-size-12">
                            <span class="mr-2"></span>
                            Cheap!
                        </div>
                    </span>

                    <span v-else-if="col.key === 'mode'">
                        <fa :icon="['fal', 'ship']" />
                    </span>

                    <span v-else-if="col.key === 'price'" class="bookings-carriers__info flex flex__align-center" @click.stop="showInfo = true">
                        320.87

                        <fa :icon="['fal', 'info-circle']" class="ml-1" />
                    </span>

                    <span v-else>Random word</span>
                </template>
            </v-table>
        </card>
    </div>
</template>

<script>
import AppTable from '~/components/vtable/AppTable';
import Calculate from './Calculate.vue';
import RequestModal from './RequestModal.vue';

export default {
    components: {
        AppTable,
        Calculate,
        RequestModal,
    },

    data() {
        return {
            filterParams: {},
            tableLoading: false,
            showInfo: false,
            showRequest: false,
            columns: [
                {
                    title: "status",
                    key: "status",
                    toggled: true,
                    sortable: false,
                    width: "w-32",
                    className: "hidden"
                },
                {
                    title: "Mode",
                    key: "mode",
                    toggled: true,
                    sortable: true,
                    width: "w-12"
                },
                {
                    title: "Carrier",
                    key: "carrier",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Service",
                    key: "service",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Price, GBP",
                    key: "price",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Pickup date",
                    key: "pickup",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Delivery date",
                    key: "delivery_date",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Load time",
                    key: "load_time",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Emissions",
                    key: "emissions",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Own argeement",
                    key: "own_argeement",
                    toggled: true,
                    sortable: true,
                    width: "w-32"
                },
                {
                    title: "Account number",
                    key: "account",
                    toggled: true,
                    sortable: true,
                    width: "w-48"
                },
            ]
        }
    },

    methods: {
        rowClicked() {
            this.showRequest = true;
        },
    }
}
</script>

<style lang="scss">
.bookings-carriers {
    th.cursor-not-allowed {
        opacity: 0;
    }

    &__table {
        margin-top: 1px;
    }

    &__status {
        background-color: #20984BBF;
        height: 24px;
        width: 80px;
        max-width: 80px;
        color: white;
        border-radius: 40px;

        span {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid rgba(32, 152, 75, 0.25);
            background-color: white;;
        }
    }

    &__info path {
        fill: #00A3FF;
    }
}
</style>