<template>
    <div class="header-tabs bookings__tabs flex flex__align-center">
        <div
            v-for="(item, index) in tabs"
            :key="index"
        >
            <div class="flex flex__align-center">
                <div 
                    class="bookings__tabs-item f-size-16 pt-4 cursor-pointer"
                    :class="{active: item.active}"
                    @click="setCurrentTab(item)"
                >
                    {{ index + 1 }}.{{ item.title }}
                </div>

                <fa v-if="index != tabs.length - 1" :icon="['fal','chevron-right']" class="ml-1 mr-1" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabs: [
                { title: 'Address', active: true },
                { title: 'Shipment details', active: false },
                { title: 'Carriers', active: false },
                { title: 'Confirmation', active: false },
            ],
        }
    },

    methods: {
        setCurrentTab(tab) {
            this.tabs.forEach(item => { item.active = false });

            tab.active = true;

            this.$emit('update', tab.title.toLowerCase());
        },
    },
}
</script>